import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaCheckCircle } from "react-icons/fa"
import "./index.css"

import Sidebar from "../components/sidebar/Sidebar"
import TechTag from "../components/tags/TechTag"

const AboutPage = (props) => {
  const labels = props.data.site.siteMetadata.labels
  const aboutTags = ["react", "nodejs", "html", "css"]
  const tags = {}
  labels.forEach((label) => {
    aboutTags.forEach((tag) => {
      if (tag === label.tag) {
        tags[tag] = label.name
      }
    })
  })

  return (
    <Layout>
      <SEO title="About" />
      <div className="post-page-main">
        <div className="sidebar px-4 py-2">
          <Sidebar />
        </div>

        <div className="post-main">
          <SEO title="About" />
          <div className="mt-3">
            <h2 className="heading">About</h2>
            <p>
              Joseph Chow is a Full Stack Web Developer from Toronto, Canada.
              After graduating at York University with a degree in
              Communications, he worked in sales for three years. Always wanting
              to learn, he started learning web development and web design
              online.
            </p>
            <p>
              Coming from a sales background, the constantly evolving nature of
              web development quickly became his favourite part about the new
              role. Now, he is ready to give back and show others how.
            </p>
            <p>
              As a hobby, Joseph enjoys reading and playing guitar. He always
              makes it a goal to learn one new tech each month, and he believes
              you can too.
            </p>
            <br />
            <h4>Features</h4>
            <div>
              <span className="text-success d-inline-block" title="blazing">
                <FaCheckCircle size={26} style={{ color: "success" }} />
              </span>
              <p className="d-inline-block ml-3 w-75 align-top">
                Blazing fast, as you'd expect from a Gatsby site
              </p>
            </div>
            <div>
              <span className="text-success d-inline-block" title="tags">
                <FaCheckCircle size={26} style={{ color: "success" }} />
              </span>
              <p className="d-inline-block ml-3 w-75 align-top">
                Tech tags designed for web developers
              </p>
              <div className="ml-5">
                <TechTag
                  tag="react"
                  tech="React"
                  name={tags["react"]}
                  size={20}
                  color="deepskyblue"
                />
                <TechTag
                  tag="nodejs"
                  tech="Node.js"
                  name={tags["nodejs"]}
                  size={20}
                  color="lightgreen"
                />
                <TechTag
                  tag="html"
                  tech="HTML"
                  name={tags["html"]}
                  size={20}
                  color="darkorange"
                />
                <TechTag
                  tag="css"
                  tech="CSS"
                  name={tags["css"]}
                  size={20}
                  color="teal"
                />
              </div>
            </div>
            <div className="mt-4">
              <span className="text-success d-inline-block" title="prism">
                <FaCheckCircle size={26} style={{ color: "success" }} />
              </span>
              <p className="d-inline-block ml-3 w-75 align-top">
                Includes Prism for code block styling in markdown files
              </p>
            </div>
            <div>
              <span className="text-success d-inline-block" title="icons">
                <FaCheckCircle size={26} style={{ color: "success" }} />
              </span>
              <p className="d-inline-block ml-3 w-75 align-top">
                Developer-relevant social-media icon links including GitHub,
                Stack Overflow and freeCodeCamp
              </p>
            </div>
            <div>
              <span className="text-success d-inline-block" title="mobile">
                <FaCheckCircle size={26} style={{ color: "success" }} />
              </span>
              <p className="d-inline-block ml-3 w-75 align-top">
                Mobile responsive, of course
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query aboutQuery {
    site {
      siteMetadata {
        labels {
          tag
          tech
          name
          size
          color
        }
      }
    }
  }
`

export default AboutPage
